/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #FFFDF2;
  color: #001F3F;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #001F3F;
}

/* Header Styles */
.App-header {
  background-color: #156082;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 1rem;
}

.header-logo {
  max-width: 200px;
  height: auto;
}

.App-header nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.App-header nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.App-header nav li {
  display: flex;
  align-items: center;
}

.language-switcher button {
  background-color: #f67e20;
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: clamp(0.875rem, 2vw, 1rem);
  display: flex;
  align-items: center;
}

.language-switcher button:hover {
  background-color: #c65c12;
}

.language-flag {
  width: 25px;
  margin-right: 8px;
}

/* Hero Section */
#hero {
  background-image: url('/public/Assets/BackgroundHero.jpg');
  background-size: cover;
  background-position: center;
  padding: clamp(2rem, 5vw, 6rem) 1rem;
  text-align: center;
  position: relative;
}

#hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

#hero h2, #hero p, #hero button {
  position: relative;
  z-index: 2;
}

#hero h2 {
  font-size: clamp(1.75rem, 4vw, 2.625rem);
  margin-bottom: 1.25rem;
  color: #001F3F;
}

#hero p {
  font-size: clamp(1rem, 2vw, 1.125rem);
  margin-bottom: 1.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: #444;
}

#hero button {
  background-color: #f67e20;
  color: #fff;
  border: none;
  padding: 0.875rem 1.5rem;
  border-radius: 6px;
  font-size: clamp(1rem, 2vw, 1.125rem);
  cursor: pointer;
}

#hero button:hover {
  background-color: #c65c12;
}

/* Services Section Styles */
#services {
  padding: clamp(2rem, 5vw, 3.75rem) 1rem;
  background-color: #FFFDF2;
}

#services h2 {
  font-size: clamp(1.5rem, 3vw, 1.875rem);
  text-align: center;
  margin-bottom: 2.5rem;
}

.services-grid-top,
.services-grid-bottom {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  display: grid;
  gap: 2rem;
  width: 100%;
  max-width: 1400px;
}

.services-grid-top {
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 2rem;
}

.services-grid-bottom {
  grid-template-columns: repeat(2, 1fr);
  max-width: 950px; /* Più stretto per le due card */
}

#services li {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

#services li img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

#services li h3 {
  font-size: clamp(1.25rem, 2.5vw, 1.375rem);
  padding: 1rem;
  margin: 0;
}

#services li p {
  font-size: clamp(0.875rem, 2vw, 1rem);
  line-height: 1.6;
  padding: 0 1rem 1rem;
  margin: 0;
}

/* About Section */
#about {
  background-image: url('/public/Assets/BackgroundAbout.jpg');
  background-size: cover;
  background-position: center;
  padding: clamp(2rem, 5vw, 6rem) 1rem;
  text-align: center;
  position: relative;
}

#about h2 {
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin-bottom: 1.5rem;
}

#about p {
  font-size: clamp(1rem, 2vw, 1.125rem);
  line-height: 1.8;
  margin-bottom: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Contact Section */
#contact {
  padding: clamp(2rem, 5vw, 3.75rem) 1rem;
  background-color: #f5f5f5;
  text-align: center;
}

.contact-card-container {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: clamp(1.5rem, 4vw, 2rem);
  width: 100%;
  max-width: 400px;
}

.contact-card h2 {
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin-bottom: 1.25rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-btn {
  background-color: #f67e20;
  color: white;
  font-size: clamp(0.875rem, 2vw, 1.1rem);
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  transition: background-color 0.3s, transform 0.2s;
}

.contact-btn:hover {
  background-color: #c65c12;
  transform: scale(1.05);
}

/* Footer */
.footer {
  background-color: #156082;
  color: #FFFDF2;
  padding: 30px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-left,
.footer-center,
.footer-right {
  flex: 1;
  min-width: 250px;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.footer-left img {
  max-width: 250px;
  height: auto;
}

.footer-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  text-align: center;
  width: 100%; /* Aggiunto per garantire l'allineamento del testo */
}

.footer-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer-copyright {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
}

.footer-info p {
  margin: 0;
  width: 100%; /* Aggiunto per garantire l'allineamento del testo */
}


/* Tablet Breakpoint */
@media (max-width: 1024px) {
  .services-grid-top,
  .services-grid-bottom {
    grid-template-columns: repeat(2, 1fr);
    max-width: 900px;
  }
}

/* Mobile Breakpoint */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    gap: 1rem;
  }

  .header-logo {
    max-width: 160px;
  }

  .App-header nav ul {
    flex-direction: column;
    width: 100%;
  }

  .App-header nav li {
    width: 100%;
    justify-content: center;
  }

  .language-switcher button {
    width: 100%;
    justify-content: center;
  }

  .services-grid-top,
  .services-grid-bottom {
    grid-template-columns: 1fr;
    max-width: 500px;
    gap: 1.5rem;
  }

  #services li img {
    height: 200px;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  .footer-info {
    align-items: center;
    width: 100%;
  }

  .footer-info p {
    text-align: center;
    padding: 0 1rem;
  }

  .footer-left img {
    max-width: 160px;
  }

  .footer-copyright {
    padding: 0 1rem;
  }
}

/* Small Mobile Breakpoint */
@media (max-width: 380px) {
  .header-logo {
    max-width: 140px;
  }

  #hero h2 {
    font-size: clamp(1.25rem, 4vw, 1.5rem);
  }

  .contact-card {
    padding: 1rem;
  }
}